<template>
  <div class="slider">
    <div class="slider__text">
      <p>{{ title }}</p>
      <p>{{ thousandSeparator(value) }} {{ options.postfix }}</p>
    </div>
    <VueSlider
      v-model="value"
      v-bind="options"
      :dot-size="20"
      :clickable="false"
      @dragEnd="dragEndCallback"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import { thousandSeparator } from "@/functions";
import VueSlider from "vue-slider-component";

export default {
  components: {
    VueSlider,
  },
  methods: {
    thousandSeparator(value) {
      return thousandSeparator(value);
    },
  },
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    return {
      value,
    };
  },
  props: {
    title: undefined,
    modelValue: undefined,
    options: undefined,
    dragEndCallback: undefined,
  },
};
</script>

<style lang="scss">
.slider {
  @apply p-2;

  &__text {
    @apply flex justify-between font-semibold;
  }
}

.vue-slider-rail {
  @apply bg-gray-400;
}

.vue-slider-process {
  @apply bg-red-600;
}

.vue-slider-dot {
  @apply bg-white border-red-600 rounded-full;
  border-width: 4px;
}

.vue-slider-dot-tooltip-text {
  @apply hidden;
}
</style>
