/**********************/
/* THOUSAND SEPARATOR */
/**********************/
export function thousandSeparator(value) {
  return value !== null && value !== undefined && (value + "").length > 0
    ? Math.round(value)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    : "";
}
