<template>
  <div class="entry" v-if="isVisible">
    <img src="@/assets/logo.png" class="entry__image" />
    <Calculate
      :terms-options="termsOptions"
      :down-payment-options="downPaymentOptions"
      :outside-data="{
        firstRegistrationDate,
        dealerId,
        price,
      }"
      :is-loading-update="isLoadingUpdate"
    />
    <div v-if="isLoading" class="entry__loader">
      <Loader />
    </div>
  </div>
</template>

<script>
import Calculate from "./Calculate.vue";
import Loader from "./Loader.vue";

export default {
  components: {
    Calculate,
    Loader,
  },
  data() {
    return {
      termsOptions: undefined,
      downPaymentOptions: undefined,
      isLoading: false,
      firstRegistrationDate: undefined,
      isVisible: true,
    };
  },
  props: {
    firstRegistrationDateProp: { default: undefined, type: String },
    price: undefined,
    dealerId: undefined,
  },
  methods: {
    getTermsOptions() {
      const max = this.getMaxTerms();

      return {
        value: max,
        min: 12,
        max: max,
        interval: 1,
        postfix: "måneder",
      };
    },
    getDownPaymentOptions() {
      const max = this.sanitizeDownPayment(this.price * 0.8);
      const min = this.sanitizeDownPayment(this.price * 0.2);
      const startValue = this.sanitizeDownPayment(this.price * 0.201);

      return {
        value: startValue,
        min: min,
        max: max,
        interval: 1000,
        postfix: "kr.",
      };
    },
    sanitizeDownPayment(value) {
      return Math.ceil(value / 1000) * 1000;
    },
    getMaxTerms() {
      const today = new Date();
      let months;
      months =
        (today.getFullYear() - this.firstRegistrationDate.getFullYear()) * 12;
      months -= this.firstRegistrationDate.getMonth();
      months += today.getMonth();

      let financeTerms = 0;

      if (months <= 12) {
        financeTerms = 96;
      } else if (months <= 36) {
        financeTerms = 84;
      } else if (months <= 60) {
        financeTerms = 72;
      } else if (months <= 72) {
        financeTerms = 60;
      } else if (months <= 96) {
        financeTerms = 48;
      } else if (months <= 119) {
        financeTerms = 36;
      }

      if (months > 119) {
        this.isVisible = false;
      }

      return financeTerms;
    },
    setupData() {
      this.firstRegistrationDate = new Date(this.firstRegistrationDateProp);
    },
    isLoadingUpdate(value) {
      this.isLoading = value;
    },
  },
  created() {
    this.setupData();

    this.termsOptions = this.getTermsOptions();
    this.downPaymentOptions = this.getDownPaymentOptions();
  },
};
</script>

<style lang="scss" scoped>
.entry {
  @apply text-center bg-white relative p-3;

  &__image {
    @apply mx-auto w-64 mt-4 mb-7;
  }

  &__loader {
    @apply absolute inset-0 bg-gray-50 opacity-75 z-10 flex justify-center items-center;
  }
}
</style>
